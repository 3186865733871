import axios from 'axios';
import authHeader from './auth-header';
// import api from './api.service';

const API_URL = window._env_.REACT_APP_API_HOST;

class SettingsService {
  getlocations() {
    return axios.get(API_URL + '/v1/countries', { headers: authHeader() });
  }
  getlanguages() {
    return axios.get(API_URL + '/v1/languages', { headers: authHeader() });
  }
  getdepartments() {
    return axios.get(API_URL + '/v1/departments', { headers: authHeader() });
  }
  getGoogleAuthSsoUrl() {
    return axios.get(API_URL + `/v1/google/sso/authorization-url?redirect=USER&redirectUrl=${window.location.hostname}`, { headers: {} });
  }
  getOutlookAuthSsoUrl() {
    return axios.get(API_URL + `/v1/outlook/sso/authorization-url?redirect=USER&redirectUrl=${window.location.hostname}`, { headers: {} });
  }
  getOktaAuthSsoUrl(email = '') {
    return axios.get(API_URL + `/v1/okta/sso/authorization-url?redirect=USER&redirectUrl=${window.location.hostname}&email=${email}`, { headers: {} })
  }
}

export default new SettingsService();
