import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { codePage, icon } from './src';
import cardsService from "src/services/cards.service";
import { useQuery } from "src/hooks";


const AwsLogin = () => {
    let query = useQuery();
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [templateCode, id, mail] = query.get('redirect-link').split(',');

    const lang = query.get('language') ? query.get('language') : ''
    const hasQr = query.get('qrCode') == 'true'

    useEffect(() => {
        document.title = 'Amazon Web Services Sign-In'
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = icon;
        document.getElementById('signin_button').addEventListener('click', () => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`))

        if (hasQr) {
            cardsService.followQrLink(id).then(() => {
                sessionStorage.setItem('followLink', id)
            })
        } else {
            cardsService.followLink(id).then(() => {
                sessionStorage.setItem('followLink', id)
            })
        }
    }, [])

    return (
        <div dangerouslySetInnerHTML={{ __html: codePage }}>

        </div>


    )
}

export default AwsLogin