import React, { useEffect, useState } from 'react'
import './styles.css'
import Spacer from 'src/common/components/Spacer'
import { Button, Drawer } from '@mui/material';
import ActivityIcon, { activityTypes } from 'src/common/icons/activityIcon';
// import { useLocation } from 'react-router-dom';
import { useGetAccountQuery, useGetCurrentActivityQuery, useGetSimulationStatsQuery } from 'src/services/user.service';
import TimeLine from './components/timeline';
// import { useQuery } from 'src/hooks';
import dayjs from 'dayjs';
import { SettingsContext } from 'src/App';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import utc from "dayjs/plugin/utc"

const TargetImage = ({ style }) => <svg style={style} className='target-img' width="166" height="227" viewBox="0 0 166 227" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_ddddd_762_53920)">
    <circle cx="100.853" cy="50.8529" r="39.1544" fill="url(#paint0_linear_762_53920)" stroke="url(#paint1_linear_762_53920)" strokeWidth="9.39705" />
    <circle cx="100.857" cy="50.8522" r="17.0539" fill="url(#paint2_linear_762_53920)" stroke="url(#paint3_linear_762_53920)" strokeWidth="9.74508" />
    <g opacity="0.5" filter="url(#filter1_f_762_53920)">
      <path d="M129.094 24.9414L100.858 50.8825L103.518 51.843L133.129 29.7121L129.094 24.9414Z" fill="#002313" />
    </g>
    <g opacity="0.25" filter="url(#filter2_f_762_53920)">
      <path d="M121.126 31.1908L128.925 25.345L136.719 27.2931L124.538 13.1625L119.178 20.9586L121.126 31.1908Z" fill="#914907" />
    </g>
    <path d="M130.698 25.5469L102.917 50.7864C101.933 51.6807 100.41 51.6077 99.5157 50.6233C98.6213 49.6389 98.6943 48.1159 99.6787 47.2215L127.46 21.982L130.698 25.5469Z" fill="url(#paint4_linear_762_53920)" />
    <mask id="path-6-inside-1_762_53920" fill="white">
      <path d="M144.341 23.543L131.583 35.1337L119.989 32.2314L132.747 20.6406L144.341 23.543Z" />
    </mask>
    <path d="M144.341 23.543L157.237 37.7378L183.76 13.6409L148.998 4.93874L144.341 23.543ZM131.583 35.1337L126.925 53.7379L136.882 56.2304L144.479 49.3285L131.583 35.1337ZM119.989 32.2314L107.093 18.0365L80.5693 42.1334L115.332 50.8356L119.989 32.2314ZM132.747 20.6406L137.404 2.03641L127.447 -0.456084L119.85 6.44579L132.747 20.6406ZM131.444 9.34811L118.686 20.9388L144.479 49.3285L157.237 37.7378L131.444 9.34811ZM136.24 16.5295L124.646 13.6271L115.332 50.8356L126.925 53.7379L136.24 16.5295ZM132.885 46.4262L145.643 34.8355L119.85 6.44579L107.093 18.0365L132.885 46.4262ZM128.089 39.2449L139.683 42.1472L148.998 4.93874L137.404 2.03641L128.089 39.2449Z" fill="url(#paint5_linear_762_53920)" mask="url(#path-6-inside-1_762_53920)" />
    <mask id="path-8-inside-2_762_53920" fill="white">
      <path d="M119.989 32.2305L132.746 20.6398L132.304 10.2936L119.546 21.8843L119.989 32.2305Z" />
    </mask>
    <path d="M119.989 32.2305L132.885 46.4253L102.578 73.9594L100.828 33.0503L119.989 32.2305ZM132.746 20.6398L151.907 19.8199L152.291 28.7943L145.643 34.8346L132.746 20.6398ZM132.304 10.2936L119.407 -3.9013L149.714 -31.4354L151.464 9.47369L132.304 10.2936ZM119.546 21.8843L100.385 22.7041L100.001 13.7297L106.65 7.68941L119.546 21.8843ZM107.092 18.0356L119.85 6.4449L145.643 34.8346L132.885 46.4253L107.092 18.0356ZM113.586 21.4596L113.143 11.1134L151.464 9.47369L151.907 19.8199L113.586 21.4596ZM145.2 24.4884L132.442 36.0791L106.65 7.68941L119.407 -3.9013L145.2 24.4884ZM138.707 21.0644L139.149 31.4106L100.828 33.0503L100.385 22.7041L138.707 21.0644Z" fill="url(#paint6_linear_762_53920)" mask="url(#path-8-inside-2_762_53920)" />
  </g>
  <defs>
    <filter id="filter0_ddddd_762_53920" x="0" y="0" width="165.706" height="226.707" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_53920" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dx="-1" dy="6" />
      <feGaussianBlur stdDeviation="6.5" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
      <feBlend mode="normal" in2="effect1_dropShadow_762_53920" result="effect2_dropShadow_762_53920" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dx="-5" dy="23" />
      <feGaussianBlur stdDeviation="12" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
      <feBlend mode="normal" in2="effect2_dropShadow_762_53920" result="effect3_dropShadow_762_53920" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dx="-11" dy="53" />
      <feGaussianBlur stdDeviation="16" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
      <feBlend mode="normal" in2="effect3_dropShadow_762_53920" result="effect4_dropShadow_762_53920" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dx="-19" dy="94" />
      <feGaussianBlur stdDeviation="19" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
      <feBlend mode="normal" in2="effect4_dropShadow_762_53920" result="effect5_dropShadow_762_53920" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect5_dropShadow_762_53920" result="shape" />
    </filter>
    <filter id="filter1_f_762_53920" x="97.6475" y="21.7305" width="38.6926" height="33.3242" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="1.60547" result="effect1_foregroundBlur_762_53920" />
    </filter>
    <filter id="filter2_f_762_53920" x="115.967" y="9.95311" width="23.9632" height="24.4492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="1.60547" result="effect1_foregroundBlur_762_53920" />
    </filter>
    <linearGradient id="paint0_linear_762_53920" x1="140.79" y1="49.2867" x2="64.8309" y2="49.2867" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" />
      <stop offset="1" stopColor="#E6FFF5" />
    </linearGradient>
    <linearGradient id="paint1_linear_762_53920" x1="144.706" y1="50.8529" x2="58.5662" y2="50.8529" gradientUnits="userSpaceOnUse">
      <stop stopColor="#01BC63" />
      <stop offset="1" stopColor="#01BA96" />
    </linearGradient>
    <linearGradient id="paint2_linear_762_53920" x1="120.826" y1="50.0691" x2="82.8461" y2="50.0691" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" />
      <stop offset="1" stopColor="#E6FFF5" />
    </linearGradient>
    <linearGradient id="paint3_linear_762_53920" x1="122.784" y1="50.8522" x2="79.7137" y2="50.8522" gradientUnits="userSpaceOnUse">
      <stop stopColor="#01BC63" />
      <stop offset="1" stopColor="#01BA96" />
    </linearGradient>
    <linearGradient id="paint4_linear_762_53920" x1="115.769" y1="31.1674" x2="120.154" y2="36.04" gradientUnits="userSpaceOnUse">
      <stop stopColor="#F2EFEE" />
      <stop offset="0.388696" stopColor="white" />
      <stop offset="0.941171" stopColor="#A5A192" />
    </linearGradient>
    <linearGradient id="paint5_linear_762_53920" x1="101.067" y1="3.51732" x2="141.761" y2="33.7566" gradientUnits="userSpaceOnUse">
      <stop offset="0.560018" stopColor="#00E55C" />
      <stop offset="1" stopColor="#BDFEF2" />
    </linearGradient>
    <linearGradient id="paint6_linear_762_53920" x1="110.101" y1="-6.25821" x2="132.527" y2="27.866" gradientUnits="userSpaceOnUse">
      <stop offset="0.560018" stopColor="#88ECC9" />
      <stop offset="1" stopColor="#A1EFCE" />
    </linearGradient>
  </defs>
</svg>


const ActivityStar = ({ style, size = 50 }) => {
  return (
    <svg style={style} width={size} height={size} viewBox={`0 0 50 50`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_762_53898)">
        <path d="M23.2798 15.2941C23.8213 13.6278 26.1787 13.6278 26.7202 15.2941L28.1861 19.806C28.4283 20.5512 29.1227 21.0557 29.9063 21.0557H34.6503C36.4024 21.0557 37.1309 23.2978 35.7134 24.3277L31.8755 27.1161C31.2415 27.5767 30.9763 28.3931 31.2184 29.1383L32.6844 33.6501C33.2258 35.3165 31.3186 36.7022 29.9011 35.6723L26.0631 32.8839C25.4292 32.4233 24.5708 32.4233 23.9369 32.8839L20.0989 35.6723C18.6814 36.7022 16.7742 35.3165 17.3156 33.6501L18.7816 29.1383C19.0237 28.3931 18.7585 27.5767 18.1245 27.1161L14.2866 24.3277C12.8691 23.2978 13.5976 21.0557 15.3497 21.0557H20.0937C20.8773 21.0557 21.5717 20.5512 21.8139 19.806L23.2798 15.2941Z" fill="url(#paint0_radial_762_53898)" />
      </g>
      <path d="M27.2099 15.135C26.5144 12.9942 23.4857 12.9942 22.7901 15.135L21.3241 19.6468C21.1509 20.1799 20.6542 20.5407 20.0937 20.5407H15.3497C13.0987 20.5407 12.1628 23.4212 13.9839 24.7443L17.8218 27.5328C18.2753 27.8622 18.465 28.4461 18.2918 28.9792L16.8258 33.491C16.1302 35.6318 18.5805 37.4121 20.4016 36.0889L24.2396 33.3005C24.693 32.9711 25.307 32.9711 25.7604 33.3005L29.5984 36.0889C31.4195 37.4121 33.8698 35.6318 33.1742 33.491L31.7082 28.9792C31.535 28.4461 31.7247 27.8622 32.1782 27.5328L36.0161 24.7443C37.8372 23.4212 36.9013 20.5407 34.6503 20.5407H29.9063C29.3458 20.5407 28.8491 20.1799 28.6759 19.6468L27.2099 15.135Z" stroke="#FFE600" strokeWidth="1.02997" />
      <mask id="mask0_762_53898" maskUnits="userSpaceOnUse" x="13" y="14" width="24" height="23">
        <path d="M23.2798 15.2941C23.8213 13.6278 26.1787 13.6278 26.7202 15.2941L28.1861 19.806C28.4283 20.5512 29.1227 21.0557 29.9063 21.0557H34.6503C36.4024 21.0557 37.1309 23.2978 35.7134 24.3277L31.8755 27.1161C31.2415 27.5767 30.9763 28.3931 31.2184 29.1383L32.6844 33.6501C33.2258 35.3165 31.3186 36.7022 29.9011 35.6723L26.0631 32.8839C25.4292 32.4233 24.5708 32.4233 23.9369 32.8839L20.0989 35.6723C18.6814 36.7022 16.7742 35.3165 17.3156 33.6501L18.7816 29.1383C19.0237 28.3931 18.7585 27.5767 18.1245 27.1161L14.2866 24.3277C12.8691 23.2978 13.5976 21.0557 15.3497 21.0557H20.0937C20.8773 21.0557 21.5717 20.5512 21.8139 19.806L23.2798 15.2941Z" fill="url(#paint1_radial_762_53898)" />
      </mask>
      <g mask="url(#mask0_762_53898)">
        <ellipse cx="20.8958" cy="14.2434" rx="13.7043" ry="13.7043" fill="url(#paint2_radial_762_53898)" />
      </g>
      <defs>
        <filter id="filter0_d_762_53898" x="-0.000864983" y="0.504506" width="50.0017" height="49.0613" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="6.76923" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.92 0 0 0 0 1 0 0 0 0 0 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_53898" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_53898" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_762_53898" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.6348 16.1217) rotate(37.4894) scale(39.891)">
          <stop stopColor="#FFE600" />
          <stop offset="1" stopColor="#FFF500" />
        </radialGradient>
        <radialGradient id="paint1_radial_762_53898" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.6348 16.1217) rotate(37.4894) scale(39.891)">
          <stop stopColor="#FFC700" />
          <stop offset="1" stopColor="#FF9900" />
        </radialGradient>
        <radialGradient id="paint2_radial_762_53898" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.9044 11.113) rotate(55.9379) scale(22.8145)">
          <stop offset="0.140625" stopColor="#EBFF00" />
          <stop offset="1" stopColor="#FFFBF5" />
        </radialGradient>
      </defs>
    </svg>
  )
}

// const StarsRewardStars = ({ count }) => {
//   return (<>
//     {count == 1 && <>
//       <ActivityStar size={150} />
//     </>}
//     {count == 2 && <>
//       <ActivityStar size={150} />
//       <ActivityStar size={150} />
//     </>}
//     {count == 3 && <>
//       <ActivityStar size={150} />
//       <ActivityStar size={150} />
//       <ActivityStar size={150} />
//     </>}
//     {count > 3 && <>
//       <p className='activity-item-stars-count' style={{ fontSize: '92px', marginRight: '-20px' }}>{count}</p>
//       <ActivityStar size={150} />
//     </>}
//   </>)
// }


const DashBoard = ({ onCloseTips }) => {
  dayjs.extend(utc)
  // const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [showIntro, setShowIntro] = useState(!localStorage.getItem('introShown'))
  // const [showFirstReward, setShowFirstReward] = useState(false)
  const [viewAllActivities, setViewAllActivities] = useState(false)
  const [introStep, setIntroStep] = useState(1)
  const [activitiesCountToShow, setActiviactivitiesCountToShow] = useState(50)

  const settings = useContext(SettingsContext)

  const { data: accountData, refetch } = useGetAccountQuery()
  const { data: simulationData, refetch: refetchSim, } = useGetSimulationStatsQuery()
  const { data: activityData, refetch: refetchActivity, } = useGetCurrentActivityQuery()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleWindowResizeEvent = () => {
    setIsMobile(window.innerWidth < 1024)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent)
  }, [])

  useEffect(() => {
    refetch()
    refetchSim()
    refetchActivity()
  }, [])

  // useEffect(() => {
  //   if (accountData?.stars) {
  //     if (parseInt(localStorage.getItem('stars')) !== accountData?.stars) {
  //       setShowFirstReward(true)
  //       localStorage.setItem('stars', accountData.stars)
  //     }
  //   }
  // }, [accountData])

  const handleCloseIntro = () => {
    setShowIntro(false)
    onCloseTips(false)
  }

  // let query = useQuery();

  // useEffect(() => {
  //   const plus = query.get('earnedStars');
  //   if (plus) {
  //     setShowFirstReward(true)
  //   }
  // }, [])

  const handleNextIntro = () => {
    setIntroStep(introStep + 1)
    if (introStep == 4) {
      setShowIntro(false)
      onCloseTips(false)
      setIntroStep(1)
      localStorage.setItem('introShown', true)
    }
  }

  return (
    <div className='dashboard-page'>
      <div className='row mob-column'>
        <div style={{ zIndex: (showIntro || settings.showTips) && introStep == 1 && 4 }} className='gradient2 dashboard-widget'>
          <h2>{t('phishingSimulation')}</h2>
          <div className='row'>
            <p className='dashboard-widget-big-num'>{simulationData && (simulationData.totalCount == 0 ? 0 : Math.round(simulationData.reportedCount / simulationData.totalCount * 100))}%</p>
            <div className='dashboard-widget-target-progress'>
              <div className='progress' style={{ width: `${simulationData && (simulationData.totalCount == 0 ? 0 : Math.round(simulationData.reportedCount / simulationData.totalCount * 100))}%` }}></div>
              <TargetImage style={{ left: `${simulationData && (simulationData.totalCount == 0 ? 0 : Math.round(simulationData.reportedCount / simulationData.totalCount * 100))}%` }} />
              <p style={{ left: `${simulationData && (simulationData.totalCount == 0 ? 0 : Math.round(simulationData.reportedCount / simulationData.totalCount * 100))}%` }}>{t('Identified')}</p>
            </div>
          </div>

          <div style={{ marginTop: isMobile ? '20px' : '50px', alignItems: 'flex-start' }} className="row row-center mob-column">
            <div className='row row center'>
              <svg width={isMobile ? '24' : "48"} height={isMobile ? '24' : "48"} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.1" cx="24" cy="24" r="24" transform="rotate(180 24 24)" fill="white" />
                <path opacity="0.15" d="M11.625 22V13.75C11.625 13.4516 11.7435 13.1655 11.9545 12.9545C12.1655 12.7435 12.4516 12.625 12.75 12.625H35.25C35.5484 12.625 35.8345 12.7435 36.0455 12.9545C36.2565 13.1655 36.375 13.4516 36.375 13.75V22C36.375 33.8147 26.3476 37.7289 24.3454 38.3927C24.1216 38.4698 23.8784 38.4698 23.6546 38.3927C21.6524 37.7289 11.625 33.8147 11.625 22Z" fill="white" />
                <path d="M11.625 22V13.75C11.625 13.4516 11.7435 13.1655 11.9545 12.9545C12.1655 12.7435 12.4516 12.625 12.75 12.625H35.25C35.5484 12.625 35.8345 12.7435 36.0455 12.9545C36.2565 13.1655 36.375 13.4516 36.375 13.75V22C36.375 33.8147 26.3476 37.7289 24.3454 38.3927C24.1216 38.4698 23.8784 38.4698 23.6546 38.3927C21.6524 37.7289 11.625 33.8147 11.625 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p style={{ fontWeight: 500, fontFamily: 'Satoshi', fontSize: isMobile ? '24px' : '42px', marginLeft: '12px', marginRight: '6px' }}>{simulationData && (simulationData.totalCount == 0 ? 0 : Math.round(simulationData.phishedCount / simulationData.totalCount * 100))}%</p>
              <p>{t('Not identified')}</p>
            </div>

            <div className='row row center' style={{ marginTop: isMobile ? '20px' : '0px' }}>
              <svg style={{ marginLeft: isMobile ? '0px' : '40px' }} width={isMobile ? '24' : "48"} height={isMobile ? '24' : "48"} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.1" cx="24" cy="24" r="24" transform="rotate(180 24 24)" fill="white" />
                <path d="M33 15L16.5 31.5L15.75 32.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M29.625 33H15V18.375" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <p style={{ fontWeight: 500, fontFamily: 'Satoshi', fontSize: isMobile ? '24px' : '42px', marginLeft: '12px', marginRight: '6px' }}>{simulationData && (simulationData.totalCount == 0 ? 0 : Math.round(simulationData.missedCount / simulationData.totalCount * 100))}%</p>
              <p>{t('Missed')}</p>
            </div>
          </div>

        </div>
        <div style={{ zIndex: (showIntro || settings.showTips) && introStep == 2 && 4 }} className='gradient2 dashboard-widget'>
          <div className='row row-center'>
            <h2>{t('Activities')}</h2>
            <Spacer />
            <button onClick={() => setViewAllActivities(true)} className='row row center text-button' style={{ color: '#fff', textDecoration: 'none', opacity: '0.8', '& :hover': { opacity: '1' } }} href="/">{t('View all')}
              <svg style={{ margin: '-2px 0px 0px 8px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.9585 10.2109H15.8335" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.2085 15.8359L15.8335 10.2109L10.2085 4.58594" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </button>
          </div>

          <div className="column activities">
            {activityData && activityData.length == 0 && <p style={{ textAlign: 'center', margin: '40px 0px', opacity: '0.7' }}>{t('no Activities yet')}</p>}
            {activityData && activityData.slice(0, isMobile ? 2 : 3).map(el => (
              <div key={el.actionAt.replaceAll(' ', '')} className='row row-center activity-item'>

                <div className='activity-item-icon'>
                  <ActivityIcon type={el.name} />
                </div>
                <div className='column'>
                  <p className='activity-item-time'>{dayjs.utc(el.actionAt).local().format('YYYY MMM DD HH:mm:ss')}</p>
                  {el.name == activityTypes.completedTrainingModule
                    ? <p>{t('Completed training package')}<strong>{el.description.replace('Completed training package', '')}</strong></p>
                    : <p>{t(el.description)}</p>
                  }
                </div>
                <Spacer />
                <div className='row row-center activity-item-stars'>
                  {el?.stars == 1 && <><ActivityStar /></>}
                  {el?.stars == 2 && <><ActivityStar /><ActivityStar /></>}
                  {el?.stars == 3 && <><ActivityStar /><ActivityStar /><ActivityStar /></>}
                  {el?.stars > 3 && <><p className='activity-item-stars-count'>{el.stars}</p><ActivityStar /></>}
                </div>
              </div>

            ))}

          </div>

        </div>
      </div>


      <div style={{ zIndex: (showIntro || settings.showTips) && introStep == 3 && 4 }} className='roadmap'>
        <div style={{ width: '3000px' }}>
          <div className='row'>
            <TimeLine score={accountData?.stars || 0} />
          </div>
        </div>
      </div>
      {(showIntro || settings.showTips) && <div className={`dased-frame ${introStep ? 'dased-frame' + introStep : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><rect width="100%" height="100%" fill="none" rx={isMobile ? "9" : "18"} ry={isMobile ? "9" : "18"} stroke="#fff" strokeWidth={isMobile ? "3" : "5"} strokeDasharray="14, 14" strokeDashoffset="32" strokeLinecap="square" /></svg></div>}
      {(showIntro || settings.showTips) && <div className="tutorial-overlay"></div>}

      {
        (showIntro || settings.showTips) &&
        <div className={`intro-popup ${introStep ? 'intro-popup-' + introStep : ''}`}>
          <div className='row'>
            <div className='row' style={{ alignItems: 'flex-end' }}>
              <div className='avatar'>
                <img src="/images/intro-card.png" alt="" />
              </div>
              <svg style={{ marginLeft: '-24px' }} width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_762_55285)">
                  <circle cx="18" cy="17" r="12" fill="#6427FF" />
                </g>
                <path d="M25.8715 13.6325C24.6677 10.9341 22.0604 9.08806 19.1347 8.77213C16.1794 8.45274 13.2241 9.8336 11.5082 12.2438C10.6802 13.4068 10.1821 14.7808 10.0293 16.1972C9.87134 17.6623 10.1352 19.1455 10.7375 20.4856C11.9413 23.1623 14.552 24.9893 17.4544 25.3026C20.5772 25.6402 23.7086 24.0745 25.3594 21.4117C25.559 21.0897 25.7335 20.7547 25.8862 20.4084C25.9791 20.1983 25.9479 19.9518 25.7335 19.8269C25.553 19.721 25.2448 19.7678 25.152 19.9796C24.5522 21.3422 23.5958 22.4992 22.3512 23.2985C21.1665 24.0597 19.803 24.4746 18.4282 24.4971C15.8296 24.5397 13.3205 23.1892 11.9518 20.963C11.5777 20.3554 11.3242 19.7826 11.129 19.1082C11.0318 18.7723 10.9571 18.4304 10.905 18.0849C10.8903 17.9877 10.8964 18.0294 10.8851 17.9304C10.8764 17.8558 10.8695 17.782 10.8625 17.7074C10.8478 17.5295 10.8382 17.3507 10.8356 17.1719C10.8304 16.8325 10.8469 16.4923 10.8851 16.1547C10.912 15.9203 10.9745 15.5732 11.0482 15.272C11.2114 14.6098 11.4206 14.0691 11.7313 13.4954C13.0115 11.1303 15.5328 9.60707 18.206 9.57843C20.8037 9.55066 23.305 10.9168 24.6625 13.1525C24.8404 13.445 24.9975 13.7497 25.1372 14.0621C25.231 14.2722 25.5365 14.3216 25.7187 14.2149C25.9348 14.0882 25.9652 13.8434 25.8715 13.6334V13.6325Z" fill="white" />
                <path d="M23.7018 14.0137C22.4798 11.8431 19.9958 10.5768 17.5222 10.9022C15.1554 11.2138 13.1539 12.8672 12.4101 15.1394C11.9059 16.6809 12.0612 18.3672 12.7694 19.8167C12.8701 20.0232 13.1635 20.0788 13.351 19.9694C13.5619 19.8462 13.6044 19.5945 13.5037 19.3879C12.9647 18.2856 12.8163 17.0089 13.1027 15.8147C13.3657 14.7194 13.9403 13.7759 14.7526 13.0477C15.4548 12.4194 16.3279 11.9889 17.2878 11.7971C17.3521 11.784 17.4172 11.7728 17.4823 11.7615C17.5109 11.7571 17.5404 11.7528 17.5691 11.7476C17.642 11.7363 17.5135 11.7545 17.5864 11.745C17.7105 11.7294 17.8346 11.7181 17.9596 11.7103C18.2104 11.6955 18.4621 11.6964 18.7121 11.7146C18.7789 11.7189 18.8458 11.725 18.9117 11.7328C18.9412 11.7363 18.9707 11.7398 19.0003 11.7433C19.0749 11.7519 18.9464 11.7346 19.0202 11.7459C19.1461 11.7641 19.2719 11.7866 19.3969 11.8136C19.6503 11.8682 19.8994 11.9394 20.1424 12.0279C20.4115 12.1251 20.6745 12.2475 20.9557 12.4089C21.2369 12.5704 21.5016 12.7509 21.7429 12.9566C22.2463 13.3854 22.6438 13.8723 22.9658 14.4442C23.2348 14.9216 23.9691 14.4928 23.7001 14.0146L23.7018 14.0137Z" fill="white" />
                <path d="M15.5249 22.5743C16.746 23.1706 18.1 23.3667 19.4375 23.1237C20.6118 22.9102 21.7175 22.3348 22.5802 21.512C23.0905 21.0251 23.5158 20.4436 23.8309 19.8126C23.9333 19.6069 23.8856 19.3517 23.6781 19.2311C23.4898 19.1208 23.199 19.1773 23.0966 19.3838C22.6019 20.3741 21.865 21.1613 20.9138 21.696C19.9625 22.2306 18.9662 22.4415 17.8986 22.3686C17.272 22.3261 16.5716 22.1421 15.9545 21.8409C15.7479 21.7402 15.4945 21.7845 15.373 21.9937C15.2636 22.1803 15.3183 22.4745 15.5257 22.5752L15.5249 22.5743Z" fill="white" />
                <path d="M21.5769 15.5071C21.0301 14.3215 19.861 13.5387 18.5695 13.4267C17.2737 13.3139 15.9979 13.964 15.2766 15.0341C14.5545 16.1051 14.4911 17.506 15.0527 18.6586C15.6142 19.8112 16.8224 20.5845 18.1086 20.66C18.8342 20.7025 19.5798 20.5072 20.1969 20.1262C20.8964 19.694 21.4041 19.0448 21.6984 18.2827C21.7808 18.0684 21.6072 17.8158 21.4015 17.7594C21.1611 17.6934 20.9615 17.841 20.8782 18.0562C20.6942 18.5318 20.3314 19.0075 19.8966 19.3069C19.4461 19.6167 18.9384 19.7886 18.4419 19.8112C17.469 19.8554 16.5117 19.3876 15.9822 18.5718C15.8303 18.3383 15.7323 18.1326 15.6568 17.8956C15.5665 17.6118 15.5335 17.4287 15.5231 17.164C15.5179 17.0381 15.5214 16.9123 15.5309 16.7873C15.5335 16.7509 15.537 16.7144 15.5413 16.6771C15.5387 16.6962 15.5292 16.7465 15.5439 16.6589C15.5561 16.5868 15.5691 16.5148 15.5856 16.4427C15.6134 16.3212 15.6481 16.2015 15.6897 16.0843C15.7383 15.9472 15.7531 15.9124 15.8312 15.764C16.3016 14.8692 17.2303 14.2894 18.2154 14.2651C18.7674 14.2512 19.2838 14.3892 19.7612 14.6835C20.2385 14.9777 20.5944 15.3952 20.8435 15.9359C20.9398 16.1442 21.241 16.1963 21.425 16.0886C21.6393 15.9637 21.6741 15.7163 21.5777 15.5071H21.5769Z" fill="white" />
                <defs>
                  <filter id="filter0_d_762_55285" x="0.0019269" y="0.441465" width="35.9961" height="35.9961" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1.43954" />
                    <feGaussianBlur stdDeviation="2.99904" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_55285" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_55285" result="shape" />
                  </filter>
                </defs>
              </svg>
            </div>
            <Spacer />
            <div className='close-icon' onClick={handleCloseIntro}>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.734375 0.734375L9.26562 9.26562" stroke="#1B1B26" strokeLinecap="round" />
                <path d="M0.734375 9.26562L9.26562 0.734375" stroke="#1B1B26" strokeLinecap="round" />
              </svg>
            </div>
          </div>
          {introStep == 1 && (
            <p className='intro-popup-title'>{t('introTitle1')}</p>
          )}
          {introStep == 2 && (
            <p className='intro-popup-title'>{t('introTitle2')}</p>
          )}
          {introStep == 3 && (
            <p className='intro-popup-title'>{t('introTitle3')}</p>
          )}
          {introStep == 4 && (
            <p className='intro-popup-title'>{t('introTitle4')}</p>
          )}

          {introStep == 1 && (
            <p className='intro-popup-txt '>{t('introText1')}</p>

          )}
          {introStep == 2 && (
            <p className='intro-popup-txt '>{t('introText2')}</p>

          )}
          {introStep == 3 && (
            <p className='intro-popup-txt '>{t('introText3')}</p>

          )}
          {introStep == 4 && (
            <p className='intro-popup-txt '>{t('introText4')}</p>

          )}

          <div className='row row-center intro-popup-bottom' >
            <p className='n-off'>{introStep} {t('of')} 4</p>
            <Spacer />
            <Button
              onClick={handleNextIntro}
              style={{
                background: '#6123FF'

              }}
              sx={{
                background: '#6123FF',
                textTransform: 'none'
              }} variant='contained'>{introStep == 4 ? t('Finish') : t('next')}</Button>
          </div>
          <div className={`intro-popup-arrow ${introStep ? 'intro-popup-arrow-' + introStep : ''}`}>
            <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 10L10 0L10 20L0 10Z" fill="white" />
            </svg>

          </div>
        </div>
      }


      {/* <Dialog sx={{
        '& .MuiPaper-rounded': {
          borderRadius: '24px',
          padding: '12px',
          width: '569px'
        }
      }} open={showFirstReward}
        onClose={handleCloseFirstReward}
      >
        <DialogTitle sx={{
          fontFamily: 'Satoshi',
          fontStyle: 'normal',
          fontWeight: '900',
          fontSize: '35px',
          lineHeight: '110%',
          color: '#1B1B26',
          textAlign: 'center',
          mt: '24px'

        }}>Fantastic job!</DialogTitle>
        <DialogContent>
          <div style={{ width: '100%', alignItems: 'center', marginBottom: '48px' }} className='column'>
            <p style={{ maxWidth: '374px', textAlign: 'center', marginBottom: '12px' }}>You spotted a Cyberlift training email. Keep up the greatwork with reporting!</p>
            <p style={{ maxWidth: '374px', textAlign: 'center', marginBottom: '12px' }}>Learn about the shady tricks used in this simulated phishing email and earn one more star!</p>

          </div>
          <Button
            variant={'contained'}
            fullWidth
            onClick={handleCloseFirstReward}
            sx={{ padding: '16px 0px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', borderRadius: '14px', background: '#6123FF', fontSize: '18px', lineHeight: '24px' }}
          >Next</Button>
        </DialogContent>
      </Dialog> */}

      {/* <div className={`stars-reward ${!showFirstReward && 'stars-reward-hidden'}`}> */}
      {/* <img style={{ width: '276px', marginTop: '-35px' }} src="images/achivement1.png" alt="" /> */}
      {/* <div className='level-label-wrapper'>
          <div className={`level-label row row-center `}>
            <svg width="99" height="33" viewBox="0 0 99 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.4806 2.33163C25.0921 1.04358 26.3904 0.222656 27.8162 0.222656H94.7845C97.017 0.222656 98.7388 2.18844 98.4447 4.40143L95.173 29.0168C94.9291 30.8519 93.3641 32.2227 91.5129 32.2227H16.1318C13.42 32.2227 11.6333 29.3969 12.7962 26.947L24.4806 2.33163Z" fill="#FCC536" />
              <path d="M15.6363 2.96641C16.0663 1.3487 17.5308 0.222656 19.2047 0.222656H87.4112C89.6713 0.222656 91.4011 2.23496 91.0617 4.46945L87.3226 29.0848C87.0486 30.8892 85.4973 32.2227 83.6722 32.2227H12.6614C10.2367 32.2227 8.47009 29.9252 9.09301 27.5818L15.6363 2.96641Z" fill="#FE912B" />
              <path d="M4.38409 3.36047C4.65817 1.55609 6.20944 0.222656 8.03453 0.222656H78.9063C81.1665 0.222656 82.8962 2.23496 82.5568 4.46945L78.8177 29.0848C78.5437 30.8892 76.9924 32.2227 75.1673 32.2227H4.29551C2.03539 32.2227 0.305664 30.2104 0.645078 27.9759L4.38409 3.36047Z" fill="#FC6A00" />
              <path d="M14.28 8.92266V20.7227H20.12V23.7227H11.04V8.92266H14.28ZM32.0533 23.7227H22.6533V8.92266H32.0533V11.9227H25.8933V14.8227H31.3533V17.6627H25.8933V20.7227H32.0533V23.7227ZM39.4041 23.7227L33.8641 8.92266H37.3241L40.1241 16.4027C40.4574 17.296 40.7707 18.336 41.0641 19.5227C41.3574 18.456 41.6841 17.416 42.0441 16.4027L44.8041 8.92266H48.1841L42.7441 23.7227H39.4041ZM59.8306 23.7227H50.4306V8.92266H59.8306V11.9227H53.6706V14.8227H59.1306V17.6627H53.6706V20.7227H59.8306V23.7227ZM66.2214 8.92266V20.7227H72.0614V23.7227H62.9814V8.92266H66.2214Z" fill="white" />
            </svg>

            <p>Level Name</p>
            <img src={'/images/level-icon-1.png'} alt="" />
          </div>

        </div> */}
      {/* <StarsRewardStars count={query.get('earnedStars') || 1} /> */}
      {/* </div> */}

      {/* <div className={`reward-glow ${!showFirstReward && 'reward-glow-hidden'}`}>
        <img src="/images/top-glow.png" alt="top-glow" />
      </div> */}

      <Drawer anchor='right' onClose={() => setViewAllActivities(false)} open={viewAllActivities}>
        <div
          style={{ position: 'fixed', top: '24px', right: isMobile ? '382px' : '1002px', cursor: 'pointer' }}
          onClick={() => setViewAllActivities(false)}
        >
          <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M37.5 10.5L10.5 37.5'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M37.5 37.5L10.5 10.5'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <div style={{ width: isMobile ? '350px' : '970px', padding: '32px' }} className='row'>
          <h2 className='drawer-title'>{t('Activity history')}</h2>
        </div>
        <div style={{ padding: '32px', width: isMobile ? '350px' : '970px' }} className='column'>

          {activityData && activityData.slice(0, activitiesCountToShow).map(el => (
            <div key={el.actionAt.replaceAll(' ', '')} className='row row-center' style={{ marginBottom: '20px' }}>
              <div style={{ flexShrink: 0, width: isMobile ? '24px' : '48px', height: isMobile ? '24px' : '48px', borderRadius: isMobile ? '24px' : '48px', overflow: 'hidden', background: '#F8F8FB', marginRight: '16px' }}>
                <ActivityIcon size={isMobile ? 24 : 48} type={el.name} />
              </div>
              {el.name == activityTypes.completedTrainingModule
                ? <p className='activity-history-activity-name'>{t('Completed training package')}<strong>{el.description.replace('Completed training package', '')}</strong></p>
                : <p className='activity-history-activity-name'>{t(el.description)}</p>
              }
              <div className='spacer'></div>
              {el?.stars == 1 && <><ActivityStar /></>}
              {el?.stars == 2 && <><ActivityStar /><ActivityStar /></>}
              {el?.stars == 3 && <><ActivityStar /><ActivityStar /><ActivityStar /></>}
              {el?.stars > 3 && <><p className='activity-item-stars-count'>{el.stars}</p><ActivityStar /></>}
              <p className='activity-history-datetime'>{dayjs.utc(el.actionAt).local().format(isMobile ? 'MMM DD HH:mm:ss' : 'YYYY MMM DD HH:mm:ss')}</p>
            </div>
          ))}
          {activitiesCountToShow < activityData?.length &&
            <div style={{ justifyContent: 'center' }} className='row row-center'>
              <Button onClick={() => setActiviactivitiesCountToShow(activitiesCountToShow + 50)} sx={{ color: '#6123FF' }} variant={'text'}>{t('Show more')}</Button>
            </div>
          }
        </div>
      </Drawer>
    </div >
  )
}

export default DashBoard
