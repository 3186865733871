import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import { jiraPage } from './jira';
import cardsService from "src/services/cards.service";
import { useQuery } from "src/hooks";
import { codePage, icon } from "./src";

// var template = { __html: jiraPage };

const GithubLogin = () => {
  let query = useQuery();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [templateCode, id, mail] = query.get('redirect-link').split(',');

  const lang = query.get('language') ? query.get('language') : ''
  const hasQr = query.get('qrCode') == 'true'

  useEffect(() => {
    document.title = 'Sign in to GitHub · GitHub'
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = icon;

    document.documentElement.setAttribute("data-color-mode", "auto");
    document.documentElement.setAttribute("data-light-theme", "light");
    document.documentElement.setAttribute("data-dark-theme", "dark");

    document.getElementById('singinbutton')?.addEventListener('click', () => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`))
    if (hasQr) {
      cardsService.followQrLink(id).then(() => {
        sessionStorage.setItem('followLink', id)
      })
    } else {
      cardsService.followLink(id).then(() => {
        sessionStorage.setItem('followLink', id)
      })
    }
  }, [])

  return (
    <div className="logged-out env-production page-responsive session-authentication" dangerouslySetInnerHTML={{ __html: codePage }}>

    </div>


  )
}

export default GithubLogin