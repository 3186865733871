import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window._env_.REACT_APP_API_HOST;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "/auth/login", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("app_user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  authLink(email) {
    return axios
      .post(API_URL + "/auth/user/auth-link-request", {
        email
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("app_user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  verifyOtp(email, oneTimePassword) {
    return axios
      .post(API_URL + "/auth/verify-otp", {
        email,
        oneTimePassword,
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("app_user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  forgotPass(email) {
    return axios
      .post(API_URL + "/v1/account/user/reset-password/init", {
        email,
      })
      .then(response => {
        return response.data;
      });
  }
  validateToken(token) {
    return axios
      .post(API_URL + "/auth/token-login", {
        token,
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("app_user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    return axios.post(API_URL + '/auth/logout', {}, { headers: authHeader() })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('app_user'));
  }

  authorizeGoogleSso(code) {
    return axios
      .post(API_URL + "/v1/google/sso/authorize", {
        code,
        redirect: 'USER',
        redirectUrl: window.location.hostname,
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("app_user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  authorizeOutlookSso(code) {
    return axios
      .post(API_URL + "/v1/outlook/sso/authorize", {
        code,
        redirect: 'USER',
        redirectUrl: window.location.hostname,
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("app_user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  authorizeOkta(code, email) {
    return axios.post(
      API_URL + '/v1/okta/sso/authorize',
      {
        code,
        email,
        redirect: 'USER',
        redirectUrl: window.location.hostname
      },
    ).then(response => {
      if (response.data.accessToken) {
        localStorage.setItem('app_user', JSON.stringify(response.data))
      }
      return response.data
    })
  }

}

export default new AuthService();
